import React from 'react';
import styled from 'styled-components';
import {Clock} from '@styled-icons/evil/Clock';
import {Map} from '@styled-icons/bootstrap/Map';
import {Pin} from '@styled-icons/ionicons-outline/Pin';

export default function StoreDetail(props) {
  const {store} = props;
  const imgUrl = (store.images || [])[0]?.expected_url;
  return (
    <StoreDetailWrapper>
      <div className="banner-image">
        {imgUrl && <img src={imgUrl} alt="store" />}
      </div>

      <div className="content">
        <h2>{store.name}</h2>

        <div className="info-item">
          <Clock size={18} color="#aaa" />
          <div>星期一 05:00~22:00</div>
        </div>

        <div className="info-item">
          <Map size={18} color="#aaa" />
          <div>台北市中正區杭州南路一段14-9號</div>
        </div>

        <div className="info-item">
          <Pin size={18} color="#aaa" />
          <div>99.99 km</div>
        </div>
      </div>
    </StoreDetailWrapper>
  );
}

const StoreDetailWrapper = styled.div`
  & > .banner-image {
    min-height: 200px;
    background-color: #ccc;
    & img {
      width: 100%;
      object-fit: cover;
    }
  }

  & > .content {
    padding: 20px;

    & > h2 {
      margin-bottom: 20px;
      font-size: 2rem;
    }

    & .info-item {
      display: flex;

      & > *:first-child {
        margin-right: 10px;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
`;
