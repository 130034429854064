import React from 'react';
import styled from 'styled-components';
import {Modal} from 'antd';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import StoreItem from '../../Components/StoreItem';
import StoreDetail from '../../Components/StoreDetail';

export default function StoreList(props) {
  const [stores, setStores] = React.useState([]);
  const [selectedStore, setSelectedStore] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        setStores(await JStorage.fetchAllDocuments('store'));
      } catch (ex) {}
      AppActions.setLoading(false);
    }

    fetchData();
  }, []);

  return (
    <Wrapper>
      <div className="content">
        <h2>分店資訊</h2>
        <div className="store-list">
          {stores.map((store) => {
            return (
              <StoreItem
                key={store.id}
                store={store}
                onClick={() => {
                  setSelectedStore(store);
                }}
              />
            );
          })}
        </div>
      </div>

      <Modal
        visible={selectedStore}
        onCancel={() => setSelectedStore(null)}
        bodyStyle={{padding: 0, borderRadius: 8}}
        style={{padding: 0, borderRadius: 8}}
        footer={null}
        destroyOnClose>
        <StoreDetail store={selectedStore} />
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 80px;

  & > .content {
    padding: 0 20px 20px;
    max-width: 720px;
    margin: 0 auto;
    min-height: calc(100vh - 250px);

    & > h2 {
      font-size: 2rem;
    }

    & .store-list {
      margin-top: 10px;
    }
  }
`;
